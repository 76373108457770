import React from 'react';
import {
    Box, Typography, Button, Container, Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import heroImage from '../../src/images/photo-1476304884326-cd2c88572c5f.avif';
import aboutHero from '../../src/images/aa8a0fcd-bab3-4658-a336-72006b3eec23.png';

const TestimonialCard = ({ text, name, imageSrc }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                padding: '1.5rem',
                borderRadius: '15px',
                textAlign: 'center',
                width: '220px',
                height: '130px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="body2" sx={{ fontWeight: 600, marginTop: '0.5rem' }}>
                {name}
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: 'italic', fontSize: '0.9rem' }}>
                {text}
            </Typography>
        </Paper>
    );
};

const AboutUs = () => {
    return (
        <Box>

            <Box
                sx={{
                    backgroundColor: '#FDF6EC',
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: 'center',
                }}
            >
                <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'row'},
                        justifyContent: 'center',
                        alignItems: {xs: 'center'}
                    }}>
                        <Box
                            component="img"
                            src={aboutHero}
                            alt="about-us"
                            sx={{
                                width: {xs: '220px',md:'240px'},
                                height: {xs: '300px',md: '360px'},
                                marginX: '18px'
                            }}
                        />
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 700,
                                    color: '#333',
                                    maxWidth: '700px',
                                    margin: 'auto',
                                    fontSize: {xs: '2.75rem', md: '3.75rem'}
                                }}
                            >
                                Empowering Businesses with Technology That Lasts
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{ color: '#666', marginTop: 2,
                                    fontSize: {xs: '1rem', md: '1.5rem'}
                                 }}
                            >
                                We’re revolutionizing the way businesses adopt and integrate technology.
                            </Typography>
                        </Box>
                    </Box>

                </motion.div>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60vh',
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: { xs: 'scroll', md: 'fixed' },
                    textAlign: 'center',
                    color: 'white',
                    padding: 4
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, rgb(1 87 104 / 31%), rgb(0 0 0 / 42%))',
                        zIndex: 1
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: {xs: '3rem', md: '4rem'},
                        textShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)',
                    }}
                >
                    Passionate about BUILDING SOLUTIONS that drive success and innovation
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: {md: '100vh'},
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: { xs: 'column' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F2EFE9',
                    overflow: 'hidden',
                    paddingY: { xs: 6, md: 0 },
                }}
            >
                <Box
                    sx={{
                        position: { xs: 'relative', md: 'absolute'},
                        top: {md: '50%'},
                        left: {md: '50%'},
                        transform: {md: 'translate(-50%, -50%)'},
                        textAlign: 'center',
                        zIndex: 1,
                        margin: {xs: '12px', md: '0px'}
                    }}
                >
                    <Typography variant="h3" sx={{ fontWeight: 700 }}>
                        Why Choose <span style={{ fontWeight: 900 }}>US</span>?
                    </Typography>
                </Box>


                <Box
                    sx={{
                        position: {md: 'relative'},
                        width: {md: '600px'},
                        height: {md: '600px'},
                        borderRadius: {md: '50%'},

                        display: {xs: 'flex'},
                        flexDirection: { xs: 'column' },
                        justifyContent: {xs: 'center'},
                        alignItems: {xs: 'center'},
                        flexWrap: {xs: 'wrap'},
                        gap: {xs: 2},
                    }}
                >
                    <Box
                        sx={{
                            position: {md: 'absolute'},
                            top: {md: '8%'},
                            left: {md: '6%'},
                            transform: {md: 'translate(-50%, -50%)'},
                        }}
                    >
                        <TestimonialCard
                            text="With years of experience in various sectors, we bring deep industry knowledge to every project."
                            name="Expertise Across Industries"
                        />
                    </Box>

                    <Box
                        sx={{
                            position: {md: 'absolute'},
                            top: {md: '16%'},
                            left: {md: '68%'},
                            transform: {md: 'translate(-50%, -50%)'},
                        }}
                    >
                        <TestimonialCard
                            text="We understand that every business is unique, which is why we offer personalized strategies that align with your goals."
                            name="Tailored Solutions"
                        />
                    </Box>
                    <Box
                        sx={{
                            position: {md: 'absolute'},
                            top: {md: '50%'},
                            left: {md: '124%'},
                            transform: {md: 'translate(-50%, -50%)'},
                        }}
                    >
                        <TestimonialCard
                            text="From cloud-based solutions to AI-driven innovation, we use the latest tools to deliver future-proof solutions."
                            name="Cutting-Edge Technology"
                        />
                    </Box>
                    <Box
                        sx={{
                            position: {md: 'absolute'},
                            top: {md: '84%'},
                            left: {md: '46%'},
                            transform: {md: 'translate(-50%, -50%)'},
                        }}
                    >
                        <TestimonialCard
                            text="Our success is built on the success of our clients. We prioritize your needs, working closely with you to exceed expectations."
                            name="Client-Centric Approach"
                        />
                    </Box>

                    <Box
                        sx={{
                            position: {md: 'absolute'},
                            top: {md: '50%'},
                            left: {md: '-22%'},
                            transform: {md: 'translate(-50%, -50%)'},
                        }}
                    >
                        <TestimonialCard
                            text="Our team has delivered successful projects across the globe, helping businesses enhance efficiency, reduce costs, and improve scalability."
                            name="Proven Track Record"
                        />
                    </Box>
                </Box>
            </Box>

            {/* Contact Section */}
            <Box
                sx={{
                    backgroundColor: '#015768',
                    color: 'white',
                    paddingY: 6,
                    textAlign: 'center'
                }}
            >
                <Container maxWidth="md">
                    <Typography variant="h4" component="h2" gutterBottom>
                        Ready to Get Started?
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ marginBottom: 4 }}>
                        Contact us today to see how we can help your business grow.
                    </Typography>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/contact"
                        sx={{ backgroundColor: '#eae6e3', color: '#015768' }}
                    >
                        Contact Us
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default AboutUs;
